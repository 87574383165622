import React, {Component} from "react";
import {Button, Grid, Header, Image, Modal} from "semantic-ui-react";
import {connect} from "react-redux";
import {rejectIfAnyNull} from "../controllers/utilities/env-checks";
import AndroidComm from "../controllers/utilities/unity-android-communication";
import {formatQuery} from "../controllers/utilities/string-utils";
import {getAdItem, setAdItem} from "../components/lib/ad-manage-api-lib";
import {getUA} from "react-device-detect";
import {navigate} from "../../.cache/gatsby-browser-entry";
import LoginForm from "../components/forms/login-form/login-form";
import cookie from "react-cookies";

export default class extends Component {
    constructor(props, context) {
        super(props, context);
        this.state = {
            newaccessToken: '',
            newgameId:'',
            newtransactionId:'',
            newattributionId:'',
            divStyle:'',
            url:''
        };
        this.state.divStyle = {
            width: '289px',
            borderRadius: '10px 10px 0px 0px',
            display: 'none'
        };
        let { accessToken ,gameId, transactionId, attributionId, uuid} = formatQuery(this.props.location.search);
        this.state.newaccessToken = accessToken;
        this.state.newgameId = gameId;
        this.state.newtransactionId = transactionId;
        this.state.newattributionId = attributionId;
        console.log(uuid);
        let s = decodeURI(uuid);
        s = s.replace(new RegExp('\%3D','g'),"=");
        s = s.replace(new RegExp('\%2F','g'),"/");
        console.log("ccc = " + s);
        this.state.url = Buffer.from(s, 'base64').toString('ascii');

        console.log("123");
        console.log(this.state.url);
        // rejectIfAnyNull(this.props.session, this.props.session);
        // const sUserAgent = navigator.userAgent.toLowerCase();
        // if (getUA.includes("JGGSDKWebView") === false) {
        //     return true;
        // }
        // console.log(getUA);
        // console.log(this.session);
        // rejectIfAnyNull(this.session, this.session);
    }

    async componentDidMount(){
        // 跳回广告页吧
        let issdkorapp = false;
        issdkorapp = getUA.includes("JGGSDKWebView");
        if (issdkorapp){
            //  返回广告页
            let platform = cookie.load("_platform");
            navigate(`/confirm-purchase/?gameId=${this.state.newgameId}&accessToken=${this.state.newaccessToken}&attributionId=${this.state.newattributionId}&transactionId=${this.state.newtransactionId}&platform=${platform}`);
            // console.log("sdk中");
        }else{
            console.log("跳出浏览器了");
            window.location.href = this.state.url;
        }
    }

    render() {
        return (
            <Grid textAlign='center' style={{
            height: "100vh",
                padding: "3vh"
        }} verticalAlign='middle'>
            <Grid.Column style={{maxWidth: 450}}>
    <div style={this.state.divStyle}>
            </div>
            </Grid.Column>
            </Grid>
    );
    }
};
